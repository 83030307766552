import {TemplateResult, html, render} from 'lit-html'
import {
  activate as activateNavigation,
  deactivate as deactivateNavigation,
  focus as focusNavigation,
} from './navigation'
// eslint-disable-next-line no-restricted-imports
import {fire} from 'delegated-events'
import memoize from '@github/memoize/decorator'
import {requestSubmit} from '@github-ui/form-utils'
import {target} from '@github/catalyst'
import {until} from 'lit-html/directives/until'

export interface Suggestion {
  value: string
  description?: string
  isAlpha?: boolean
  isBeta?: boolean
  isNew?: boolean
}

// Export the cache to allow it be be cleared in tests
/* eslint-disable-next-line custom-elements/no-exports-with-element */
export const memoizeCache = new Map()

// ================= DEPRECATED =================
// Use QueryBuilderElement instead of this class.
// ----------------------------------------------

/**
 * When using any code example from this comment block, replace
 * `your-component` with your component's HTML tag name.
 *
 * Catalyst components that extend this base class need to be set up as
 * described below. Using the GitHub::FilterInputComponent is recommended
 * as that sets this up for you.
 * - An element with data-target="your-component.autocompleteDropdown"
 * - An element within that with data-target="your-component.autocompleteResults"
 * - (Optional) An element with data-target="your-component.clearButton"
 *   This in turns needs data-action="click:your-component#clear"
 * - A form with data-target="your-component.searchForm"
 *   This in turns needs the following data-action values:
 *    navigation:keydown:your-component#handleFormKeydownEvent
 *    navigation:open:your-component#handleSelectedSuggestionResultEvent
 * - An input field with data-target="your-component.searchInput"
 *   This in turn needs the following data attributes:
 *   - data-initial-value - the query value at the time of page-load
 *   - data-default-value - the default query, put into the search box when
 *     the search is cleared. For most filters, this will be an empty string,
 *     but some filters may enforce a default. For example, that there must
 *     always be an "is:" value and that this defaults to "is:open"
 *   - data-suggestable-qualifiers; optional - alternatively, override
 *     fetchQualifierSuggestions and define the qualifiers in the TypeScript
 *     directly.
 *   - data-negatable-qualifiers; optional - allows you to make use of the
 *     negatableQualifiers and removeNegationFromQualifierIfSupported methods
 *   - data-action:
 *       input:your-component#updateFilterSuggestionResults
 *       focusin:your-component#updateFilterSuggestionResults
 *       focusout:delay:your-component#handleSearchBlur
 *       keydown:your-component#inputKey
 *   - data-filter-support-url; optional - if this is provided, a "learn more"
 *     link pointing to this URL will be added to the invalid search message
 *
 * When creating a subclass, you should also consider whether to override
 * any of the fields listed below the targets.
 */
export abstract class BaseFilterElement extends HTMLElement {
  @target autocompleteDropdown: HTMLElement
  @target autocompleteResults: HTMLElement
  @target clearButton: HTMLButtonElement | null
  @target searchForm: HTMLFormElement
  @target searchInput: HTMLInputElement

  showAllQualifiersIfNoneMatch = true
  fuzzyMatchQualifiers = false
  fuzzyMatchValues = true
  showSubmissionOptionIfInvalidSearchTerms = false
  suggestionsTitle = 'Available filters'
  spaceBetweenValueAndDescription = true
  selectorOfElementToActivateOnBlur: string | null = null
  unqualifiedSearchTermsAlwaysValid = true

  @memoize({cache: memoizeCache})
  async cachedJSON<T>(url: RequestInfo): Promise<T> {
    const response = await fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      const responseError = new Error()
      const statusText = response.statusText ? ` ${response.statusText}` : ''
      responseError.message = `HTTP ${response.status}${statusText}`
      throw responseError
    }
    return response.json()
  }

  // As an alternative to defining the qualifiers in the template, you can
  // override this method to return an array of Suggestions, defined in the
  // TypeScript itself.
  fetchQualifierSuggestions(): Suggestion[] {
    const attribute = 'data-suggestable-qualifiers'
    const qualifiersJSON = this.searchInput.getAttribute(attribute)
    if (qualifiersJSON === null) {
      throw new Error(`
        ${attribute} is missing from ${this.searchInput.getAttribute('data-target')}.
        Either add it or override fetchQualifierSuggestions.
      `)
    }
    return JSON.parse(qualifiersJSON)
  }

  // A list of qualifiers for which the "-" prefix can be used for negation
  // The qualifier strings should not include the colon.
  negatableQualifiers(): string[] {
    return JSON.parse(this.searchInput.getAttribute('data-negatable-qualifiers') || '[]')
  }

  removeNegationFromQualifierIfSupported(filterInput: string) {
    if (!filterInput.startsWith('-')) {
      return filterInput
    }

    // eslint-disable-next-line prefer-const
    let [qualifier, ...values] = filterInput.split(':')

    const strippedQualifier = qualifier!.substring(1)
    if (this.negatableQualifiers().includes(strippedQualifier)) {
      qualifier = strippedQualifier
    }

    if (filterInput.includes(':')) {
      qualifier += ':'
    }

    return values?.length > 0 ? `${qualifier}${values.join(':')}` : qualifier
  }

  // In the search "repo:github/github", the qualifier is "repo" (no colon).
  //
  // This method should return a Promise that resolves to an array of Suggestions
  // that are valid for the given qualifier.
  //
  // The most common pattern here is to either:
  // 1) Retrieve a hardcoded list of suggestions from a data attribute
  // 2) Retrieve a URL from a data attribute and call this.cachedJSON
  // to retrieve the suggestions from that.
  //
  // You may want to mix and match approaches depending on the qualifier passed.
  //
  // Don't forget to use removeNegationFromQualifierIfSupported to remove possible negation.
  //
  // If you're using the `UI::SuggestableSearchComponent` (which populates
  // data-suggestable-* and data-suggestable-*-path appropriately), the
  // base implementation of this method here should suffice.
  fetchSuggestionsForQualifier(qualifier: string): Promise<Suggestion[]> {
    qualifier = this.removeNegationFromQualifierIfSupported(qualifier)!
    const suggestionAttribute = `data-suggestable-${qualifier}`
    const suggestionPathAttribute = `data-suggestable-${qualifier}-path`
    if (this.searchInput.hasAttribute(suggestionAttribute)) {
      return JSON.parse(this.searchInput.getAttribute(suggestionAttribute) || '[]')
    } else if (this.searchInput.hasAttribute(suggestionPathAttribute)) {
      const url = this.searchInput.getAttribute(suggestionPathAttribute)
      if (!url) throw new Error(`${suggestionPathAttribute} not set`)
      return this.cachedJSON(url)
    } else {
      return Promise.resolve([])
    }
  }

  // Hide the dropdown and disable navigation
  hideFilterSuggestions() {
    deactivateNavigation(this.searchForm)
    this.autocompleteDropdown.hidden = true
    this.searchInput.setAttribute('aria-expanded', 'false')
    this.searchInput.removeAttribute('aria-activedescendant')
  }

  // Parses the current search query to decide what autocompletes to show
  updateFilterSuggestionResults(): void {
    const searchString = this.searchInput.value

    // The `currentString` is the text before the cursor position up until the
    // previous whitespace, ignoring whitespace in quoted values.
    // e.g. in: `repo:foo/bar reason:me| blah` it will be "reason:me"
    const textBeforeCursor = searchString.slice(0, this.searchInput.selectionEnd!)
    const currentString = (textBeforeCursor.match(/(:"[^"]+"?|\S)+$/) || [''])[0].replace(/"/g, '')

    // Make sure results are visible
    this.autocompleteDropdown.hidden = false
    this.searchInput.setAttribute('aria-expanded', 'true')

    // Update the suggestions list
    // If the current string has a ":" then we are autocompleting the value,
    // otherwise we are still autocompleting a qualifier
    let qualifier
    let value
    if (currentString.includes(':')) {
      ;[qualifier, ...value] = currentString.split(':') as [string, ...string[]]
    } else {
      qualifier = currentString
    }

    if (value != null) {
      this.renderValueSuggestions(qualifier, value.join(':'))
    } else {
      this.renderQualifierSuggestions(qualifier)
    }

    if (
      searchString.trim().length > 0 &&
      (!this.invalidSearchTerms() || this.showSubmissionOptionIfInvalidSearchTerms) &&
      !this.searchMatchesDefault()
    ) {
      // If it's a non-empty, valid search that doesn't match the default, show
      // the clear button and focus on the first item in the suggestions list:
      // the "submit" option to trigger the search automatically on return
      if (this.clearButton) this.clearButton.hidden = false
      focusNavigation(this.searchForm)
    } else {
      // Otherwise, hide the clear button, and enable keyboard navigation
      // of the suggestions list, but don't highlight anything
      if (this.clearButton) this.clearButton.hidden = true
      activateNavigation(this.searchForm)
    }
  }

  // handles a suggestion item being selected
  handleSelectedSuggestionResultEvent(event: Event) {
    const selectedSuggestion = event.target as Element

    // If clicking the documentation link, open it in a new tab
    if (selectedSuggestion.classList.contains('js-filter-input-support-url')) {
      return
    }

    // if the user selected the "search" entry, just submit the search form
    if (selectedSuggestion.hasAttribute('data-search')) {
      requestSubmit(this.searchForm)
      return
    }

    // otherwise, replace the currently typed string with the suggested string in the input field
    // or append the new value if this is a multi-value search (i.e. using commas)
    let suggestedText = selectedSuggestion.getAttribute('data-value') || ''
    // append a space if the user did not type a qualifier:
    if (suggestedText[suggestedText.length - 1] !== ':') {
      suggestedText += ' '
    }
    const textBeforeCursor = this.searchInput.value.slice(0, this.searchInput.selectionEnd!)
    const phraseBeforeCursor = textBeforeCursor.match(/(\S+)$/)?.pop() || ''
    const textAfterCursor = this.searchInput.value.slice(this.searchInput.selectionEnd!)
    //add a space after the cursor if the user is typing in the middle of the field
    const spacer = textAfterCursor[0] !== ' ' ? ' ' : ''

    if (phraseBeforeCursor) {
      if (phraseBeforeCursor.startsWith('-') && !suggestedText.startsWith('-')) {
        suggestedText = `-${suggestedText}`
      }
      if (phraseBeforeCursor.includes(',')) {
        const existingValues = phraseBeforeCursor.split(',')
        existingValues.pop()
        const suggestedValue = suggestedText.split(':').slice(1).join(':')
        existingValues.push(suggestedValue || '')
        suggestedText = existingValues.join(',')
      }
    }

    const updatedTextBeforeCursor = textBeforeCursor.replace(/\S+$/, '')
    this.searchInput.value = updatedTextBeforeCursor + suggestedText + spacer + textAfterCursor

    event.preventDefault()

    // return focus to the input field
    this.searchInput.focus()

    // move the cursor back to just after the suggested text
    const cursorPosition = updatedTextBeforeCursor.length + suggestedText.length
    this.searchInput.setSelectionRange(cursorPosition, cursorPosition)

    // trigger an input event so other code knows the field updated
    fire(this.searchInput, 'input')
  }

  handleFormKeydownEvent(event: CustomEvent) {
    if (event.detail.hotkey === 'Enter') {
      // Don't submit if only has "loading..."
      if (this.autocompleteResults.querySelector('.js-filter-loading')) {
        return
      }

      // Don't submit if we are choosing an autocomplete suggestion
      if (this.autocompleteResults.querySelector('.js-navigation-item.navigation-focus')) {
        return
      }

      requestSubmit(this.searchForm)
    }
  }

  // Clear search and refresh list of alerts when a user clicks the 'x' icon in the search bar
  clear() {
    this.searchInput.value = this.getDefaultSearch()

    if (this.getInitialValue().trim().length === 0) {
      // The query started off blank and has been cleared so it's blank again. Rather than reloading
      // the page, just clear the input and refresh the display state.
      this.updateFilterSuggestionResults()
    } else {
      // The query has been cleared, and the blank query is different from the active one: execute the
      // blank query so we refresh the list of results.
      requestSubmit(this.searchForm)
    }
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  renderQualifierSuggestions(filterString: string): void {
    if (this.showAllQualifiersIfNoneMatch) {
      this.renderMatchingOrAllQualifierSuggestions(filterString)
    } else {
      this.renderMatchingQualifierSuggestions(filterString)
    }
  }

  handleSuggestionNavigation(event: Event) {
    if (event.target != null) {
      this.searchInput.setAttribute('aria-activedescendant', (event.target as Element).id)
    }
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  // or all qualifiers if none match.
  renderMatchingOrAllQualifierSuggestions(filterString: string): void {
    const suggestions = this.fetchQualifierSuggestions()
    const matchingSuggestions = this.filterSuggestionsList(suggestions, filterString, {
      fuzzy: this.fuzzyMatchQualifiers,
    })
      /* eslint-disable-next-line github/no-then */
      .then(matching => {
        // If there are no matching suggestions, just show them all
        if (matching.length === 0) return suggestions
        return matching
      })

    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  private renderMatchingQualifierSuggestions(filterString: string): void {
    const matchingSuggestions = this.filterSuggestionsList(this.fetchQualifierSuggestions(), filterString, {
      fuzzy: this.fuzzyMatchQualifiers,
    })
    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Render autocomplete suggestions to match qualifier values from the currently typed text.
  //
  // qualifier - Qualifier to find value suggestions for.
  // filterString - Filter input to filter suggestions by.
  renderValueSuggestions(qualifier: string, filterString: string) {
    const matchingSuggestions = this.fetchMatchingSuggestions(qualifier, filterString)
    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Fetch value suggestions for the given qualifier.
  //
  // qualifier - Qualifier to fetch matching suggestions for.
  // filterString - Filter input to filter suggestions by.
  //
  // Returns a list of matching suggestions, prefixed with the given qualifier.
  async fetchMatchingSuggestions(qualifier: string, filterString: string): Promise<Suggestion[]> {
    const suggestionsPromise = this.fetchSuggestionsForQualifier(qualifier)
    const lastSearchTerm = filterString.split(',').pop() || ''
    const suggestions = await this.filterSuggestionsList(suggestionsPromise, lastSearchTerm, {
      fuzzy: this.fuzzyMatchValues,
    })

    // prepend the qualifier to each suggestion
    return suggestions.map(suggestion => ({
      value: `${qualifier}:${suggestion.value}`,
      description: suggestion.description,
    }))
  }

  // filter a (promise of a) list of suggestions by a search query
  async filterSuggestionsList(
    suggestionsPromise: Promise<Suggestion[]> | Suggestion[],
    _search: string,
    {fuzzy} = {fuzzy: true},
  ): Promise<Suggestion[]> {
    const suggestions = await suggestionsPromise
    let search = _search.trim().toLowerCase()

    // if the search is empty, just return all results
    if (!search || search.length === 0) {
      return suggestions
    }

    if (search.startsWith('-')) {
      search = search.slice(1)
    }

    return suggestions.filter(suggestion => {
      if (fuzzy) return suggestion.value.toLowerCase().includes(search)
      return suggestion.value.toLowerCase().startsWith(search)
    })
  }

  // Render a promise of suggestions into the dropdown
  // If the suggestions haven't finished loading,  "loading..." will be rendered until they do
  renderSuggestionDropdown(suggestionsPromise: Promise<Suggestion[]>): void {
    render(
      html`
        <div role="listbox" aria-label="${this.suggestionsTitle}">
          ${this.renderSearchWarningIfRequired()}
          ${this.shouldRenderSubmissionOption() ? this.renderSearchSuggestion() : ''}
          ${until(this.renderSuggestionList(suggestionsPromise), this.renderLoadingItem())}
        </div>
      `,
      this.autocompleteResults,
    )

    this.postDropdownRender()
  }

  renderSearchWarningIfRequired(): TemplateResult | string {
    const invalidTerms = this.invalidSearchTerms()
    if (!invalidTerms || invalidTerms.length === 0) return ''

    let link = html``
    const supportURL = this.getFilterSupportURL()
    if (supportURL) {
      link = html`<a
        class="js-navigation-item js-navigation-open js-filter-input-support-url px-1"
        href="${supportURL}"
        target="_blank"
      >
        Learn more about filters.
      </a>`
    }

    return html`
      <div class="color-bg-attention color-fg-muted ml-n2 mr-n2 mt-n1 py-1 px-2 js-search-warning-container">
        Sorry, we don't support the <span class="text-bold">${invalidTerms}</span> filter yet. ${link}
      </div>
    `
  }

  // The attribute retrieved here is optional, so may not be present
  getFilterSupportURL(): string | null {
    return this.searchInput.getAttribute('data-filter-support-url')
  }

  // Subclasses may wish to perform additional rendering after the
  // main dropdown has rendered.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  postDropdownRender(): void {}

  // Renders a result (for the top of the list) that the user can select to submit the search
  renderSearchSuggestion() {
    const searchString = this.searchInput.value.trim()
    if (searchString.length === 0) {
      return html``
    }

    // don't prompt users to submit a searchString containing a qualifier with no value
    // but allow them to submit if the searchString includes a value with an additional ':' at the end
    if (/:\s|:$/g.test(searchString)) {
      if (!/(?:\w+:){2,}/g.test(searchString)) return html``
    }

    return html`
      <div
        id="${this.getComponentTagName()}-search-submit-option"
        class="border-bottom-0 rounded-2 py-1 px-2 mx-0 mb-1 js-navigation-item"
        data-action="navigation:focus:${this.getComponentTagName()}#handleSuggestionNavigation"
        data-search="true"
        role="option"
      >
        <span class="text-bold">${searchString}</span> - submit
      </div>
    `
  }

  // Returns a string of all invalid search terms in the current query
  // For example 'invalid1 invalid2'. If no terms are invalid, return
  // null or the empty string.
  //
  // If the concept of an invalid term is not relevant, just return null.
  invalidSearchTerms(): string | null {
    const validQualifiers = this.fetchQualifierSuggestions().map(q => q.value)
    // Matches qualifiers such as "risk:", "enabled:", "not-enabled:", "is:", "sort:", "archived:"
    const qualifierRegex = new RegExp(/[^\s:]+:/)
    // Matches any strings that are in quotes, including escaped quotes, note that within the quotes,
    // spaces are allowed.
    const quotedStringRegex = new RegExp(/"(?:\\"|.)*?"/)
    // Matches a qualifier followed by a value, such as 'risk:high' or 'team:"my team"'
    const qualifiedSearchTerm = new RegExp(`${qualifierRegex.source}(?:${quotedStringRegex.source}|[^\\s]*)`)
    // Matches a search term that is not a qualifier, such as "repo-name"
    const unqualifiedSearchTermRegex = new RegExp(/[^\s]+/)

    const searchTermsRegex = new RegExp(`${qualifiedSearchTerm.source}|${unqualifiedSearchTermRegex.source}`, 'g')

    const parts = this.searchInput.value.match(searchTermsRegex) || []

    const invalidParts = parts.filter(part => {
      part = this.removeNegationFromQualifierIfSupported(part)!
      const colonIndex = part.indexOf(':')

      if (colonIndex === -1) {
        if (this.unqualifiedSearchTermsAlwaysValid) {
          return null
        } else {
          // Assume valid only if any of the valid qualifiers start with what's typed so far
          return !validQualifiers.some(validQualifier => validQualifier.startsWith(part))
        }
      } else {
        const typedQualifier = part.substr(0, colonIndex + 1)
        return !validQualifiers.some(validQualifier => validQualifier === typedQualifier)
      }
    })

    if (invalidParts.length === 0) {
      return null
    }

    return invalidParts.join(' ')
  }

  searchMatchesDefault(): boolean {
    const sortedSearchInput = this.searchInput.value.trim().split(' ').sort()
    const sortedDefaultSearch = this.getDefaultSearch().trim().split(' ').sort()
    return (
      sortedSearchInput.length === sortedDefaultSearch.length &&
      sortedSearchInput.every((value, index) => value === sortedDefaultSearch[index])
    )
  }

  // The query value at the time of page-load
  getInitialValue(): string {
    return this.getDataAttributeOrThrow('data-initial-value')
  }

  getComponentTagName(): string {
    return this.tagName.toLowerCase()
  }

  // The value that should be put into the search input when the user
  // clears the search. Often this will be the empty string, but some
  // filters enforce that certain qualifiers are present.
  getDefaultSearch(): string {
    return this.getDataAttributeOrThrow('data-default-value')
  }

  getDataAttributeOrThrow(attribute: string): string {
    const value = this.searchInput.getAttribute(attribute)
    if (value === null) {
      throw new Error(`${attribute} is missing from search input`)
    }
    return value
  }

  // Renders a title like "Narrow your search" in the dropdown
  // aria-hidden="true" is used to prevent screenreaders counting this
  // title as a selectable item in the list when it isn't.
  renderSuggestionsTitle() {
    return html`<p
      class="h6 width-full text-normal border-bottom color-bg-default color-fg-muted py-2 mb-2"
      aria-hidden="true"
    >
      ${this.suggestionsTitle}
    </p>`
  }

  // Renders a list of suggestions for the dropdown.
  async renderSuggestionList(_suggestions: Promise<Suggestion[]>) {
    const suggestions = await _suggestions

    const suggestionsHTML = suggestions.map(
      (suggestion, index) =>
        html`
          <div
            class="border-bottom-0 rounded-2 py-1 px-2 mx-0 mb-1 js-navigation-item"
            data-value="${suggestion.value}"
            data-action="navigation:focus:${this.getComponentTagName()}#handleSuggestionNavigation"
            aria-label="${suggestion.value} ${suggestion.description}"
            id="${this.getComponentTagName()}-suggestion-${index}"
            role="option"
          >
            <span class="text-bold">${suggestion.value}</span>${this.spaceBetweenValueAndDescription ? ' ' : ''}<span
              class="autocomplete-text-qualifier color-fg-muted"
              >&nbsp;${suggestion.description}</span
            >
            ${suggestion.isAlpha && this.alphaTag} ${suggestion.isBeta && this.betaTag}
            ${suggestion.isNew ? this.newTag : ''}
          </div>
        `,
    )

    if (suggestionsHTML.length) {
      suggestionsHTML.unshift(this.renderSuggestionsTitle())
    }

    return suggestionsHTML
  }

  renderLoadingItem() {
    return html`
      ${this.renderSuggestionsTitle()}
      <span class="js-filter-loading">loading...</span>
    `
  }

  handleSearchBlur() {
    this.hideFilterSuggestions()

    if (this.selectorOfElementToActivateOnBlur) {
      activateNavigation(document.querySelector<HTMLElement>(this.selectorOfElementToActivateOnBlur))
    }
  }

  inputKey(event: KeyboardEvent) {
    // We can't use data-hotkey since there's no corresponding UI element
    // Related discussion: https://github.com/github/code-scanning/issues/4960
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable no-restricted-syntax */
    if (event.key === 'Escape') {
      this.handleSearchBlur()
    }
    /* eslint-enable no-restricted-syntax */
  }

  shouldRenderSubmissionOption(): boolean {
    return this.showSubmissionOptionIfInvalidSearchTerms || !this.invalidSearchTerms()
  }

  static tagFn(name: string) {
    return html`<span class="lh-condensed px-1 rounded-2 border color-border-success">${name}</span>`
  }

  alphaTag = BaseFilterElement.tagFn('Alpha')
  betaTag = BaseFilterElement.tagFn('Beta')
  newTag = html`<span class="Label ml-1 Label--accent color-bg-default float-right">New</span>`
}
